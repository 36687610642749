<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.listReception')}`}} </h6>
      </CCol>
      <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
          <CButton
              color="excel"
              class="mr-1" 
              size="sm"
              @click="onBtnExport(true)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
          <CButton
              color="watch"
              size="sm"  
              @click="onBtnExport(false)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; CSV
          </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="4">
        <div class="form-group form-row" rol="group">
            <label class="col-form-label col-sm-12 col-lg-2 text-right" style="font-size:13px">{{$t('label.visit')}}</label>
            <div class="col-sm-12 col-lg-9 input-group">
                <v-select
                    class="select-adjust"
                    :placeholder="$t('label.select')"
                    v-model="Visit"
                    :options="visitFormatted"
                    :reduce="option => option.Json" 
                    :getOptionLabel="option => option.title"
                >
                    <template #no-options="{ }">
                        {{$t('label.noResultsFound')}}
                    </template>
                    <template #option="{ Json, title }">
                        {{ title }}<br />
                        <cite v-if="title!=$t('label.select')">
                            {{ Json.VoyageArrival }} - {{ 'ATA: '+Json.Ata }}
                        </cite>
                    </template>
                </v-select>
            </div>
        </div>
      </CCol>
      <CCol sm="12" lg="7">
        <div class="form-group form-row d-flex aling-items-center">
          <label class="col-form-label-sm col-sm-12 col-lg-1 text-right mt-1">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="searchFilterDate.fromDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
          <div class="input-group col-sm-5 col-lg-4 mb-2">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="true"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="searchFilterDate.toDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto">
            <CButton
                color="watch"
                square
                size="sm"
                v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                class="mx-1"
                @click="(getYardVisitList())"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
                color="danger"
                square
                size="sm"
                v-c-tooltip="{ content: $t('label.clearFilters'), placement: 'top-end'}"
                @click="(refreshComponent(), getYardVisitList())"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="3">
          <CInput
            :label="$t('label.filter')"
            :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
            :placeholder="$t('label.enterSearch')" 
            @keyup="onQuickFilterChanged($event.target.value)"         
          >
          </CInput>  
      </CCol>
      <CCol sm="12" lg="5"></CCol>
      <CCol sm="12" lg="4" class="justify-content-end">
          <CSelect
            :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
            :label="$t('label.recordsPerPage')"
            @change="onPageSizeChanged"
            :options="[50,100,500,1000]"
          >
          </CSelect>
      </CCol>
  </CRow>
  <CRow>
    <CCol col="12" xl="12">
      
    </CCol>
  </CRow>
  <CRow>
    <CCol sm="11" lg="12" xl="12" class="mb-2">
        <div class="d-flex bd-highlight">
            <div class="bd-highlight" style="width:50px">
                <CButton
                    block
                    style="height: 100vh;"
                    shape="square"
                    color="light"
                    @click="ModalColumn = true"
                >
                    <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                        <CIcon name="cil-cog" class="correct-vertical"/>
                    </div>
                </CButton>
            </div>
            <div class="flex-grow-1 bd-highlight">
                <ag-grid-vue
                  v-if="showGrid"
                  style="width: 100%; height: 100vh;"
                  class="ag-theme-alpine"
                  :getRowStyle="getRowStyle"
                  :getRowClass="getRowClass"
                  :gridOptions="gridOptions"
                  :defaultColDef="defaultColDef"
                  :localeText="localeText"
                  :columnDefs="columnDefs"
                  :rowData="RowData"
                  :suppressRowClickSelection="true"
                  :groupSelectsChildren="true"
                  :enableRangeSelection="true"
                  :pagination="true"
                  :paginationPageSize="paginationPageSize"
                  :paginationNumberFormatter="paginationNumberFormatter"
                  @grid-ready="onGridReady"
              >
              </ag-grid-vue>
            </div>
        </div>
    </CCol>
  </CRow>
  <ModalRegister
    :modal.sync="ModalColumn"
    :ModalList="columnsOption"
  />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import AgGrid from '@/_mixins/ag-grid';
import { localeKey } from '@/_helpers/locale.grid';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater,  NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
import ModalRegister from './modal-columns';
import { mapState } from 'vuex';
import YardManagement from '@/_mixins/yardManagement';
import moment from 'moment';

//DATA-------------------------
function data() {
  return {
    gridOptions: null,
    defaultColDef: null,
    paginationPageSize: null,
    paginationNumberFormatter: null,
    gridApi: null,
    columnApi: null,
    previousDate: '',
    laterDate: '',
    Visit: '',
    VisitName: {},
    VisitList: [],
    ModalColumn:false,
    searchFilterDate:{
        fromDay:"",
        toDay:"",
    },
    formatedItems: [],
    columnsOption:{
      filter:true,
      selectAll:true,
      seal:true,
      dateReception:true,
      number:true,
      broker:true,
      machine: true,
      codeIso:true,
      condition:true,
      diceContener:true,
      dua:true,
      eir: true,
      imdg:true,
      weight:true,
      volume:true,
      temperature:true,
      conected:true,
      dinTop:true,
      dinLeft:true,
      dinRight:true,
      dinFront:true,
      dinBack:true,
      rubro:true,
      governmentPrivate:true,
      origin:true,
      shippingLine:true,
      currentPosicion:true,
      area: true,
      user:true,
      device:true,
      dateTransaction:true,
      observation:true,
      damage: true,
      status: true,
      receptionBp:true,
      customsRegime:true,
      actualStatus: true,
      dayYard: true,
      weightSelected: 1,
      UnitMeasureWeigth:[
        {
            value: 1,
            label: 'TON'
        },
        {
            value: 2,
            label: 'KGM'
        }
      ],
    },
  }
}

//beforeMount
function beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
        editable: false,
        resizable: true,
        sortable: true,
        minWidth: 150,
        flex: 1,
    };
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
        return params.value.toLocaleString();
    };
}

//computeds
function RowData(){
    if(this.formatedItems.length != 0){
        let _lang = this.$i18n.locale;

        return this.formatedItems.map(item => {
            let WeigthTon = 0, WeigthKmg = 0;

            item.WeigthJson.map((e)=>{
                if(e.UnitMeasureAbbrev === "TON")
                    WeigthTon = e.Value;
                else
                    WeigthKmg = e.Value;
            })

            return {
                ...item,
                DimTop: item.DimTop&&item.DimTop>0 ? NumberFormater.formatNumber(item.DimTop, 2) : '',
                DinLeft: item.DinLeft&&item.DinLeft>0 ? NumberFormater.formatNumber(item.DinLeft, 2) : '',
                DinFront: item.DinFront&&item.DinFront>0 ? NumberFormater.formatNumber(item.DinFront, 2) : '',
                DinBack: item.DinBack&&item.DinBack>0 ? NumberFormater.formatNumber(item.DinBack, 2) : '',
                CurrentPosition: item.CurrentPositionJson.map(Position => Position.SlotCode.trim()).join(', '),
                AreaJson: item.AreaJson.map(Area => Area.YardAreaName?.trim()).join(', ') ?? '',
                DaysInYard: _lang=='en' ? item.DaysInYardEn||'' : item.DaysInYardEs||'',
                WeigthTon: WeigthTon,
                WeigthKmg: WeigthKmg
            }
        });
    }else{
        return [];
    }
}

function visitFormatted(){
    if(this.VisitList.length === 0){
        return [{
            title: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
                VisitStatus: '',
            },
        }];
    }else{
        let _lang = this.$i18n.locale;
        let chart = [{
            title: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
                VisitStatus: '',
            }
        }];

        this.VisitList.map(function(e){
            chart.push({
                title: e.VesselName,
                value: e.Visit,
                Json: {
                    ...e,
                    VoyageArrival: e.VoyageArrival,
                    Ata: DateFormater.formatDateTimeWithSlash(e.Ata)
                }
            })    
        })
        return chart;
    };
}

function localeText(){
    let AG_GRID_LOCALE = {};
    for (let index = 0; index < localeKey.length; index++) {
        const element = localeKey[index];
        AG_GRID_LOCALE[element] = this.$t('agridTable.'+element);
    }
    return AG_GRID_LOCALE;
}

function columnDefs(){
    let columnDefs = [
        {
            field: "Nro",
            headerName: '#',
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 100,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "GpoCargoName",
            headerName: this.$t('label.cargoGroup'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "ContainerCode",
            headerName: `${this.$t('label.container')}/${this.$t('label.serial')}`, 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 220,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "TpCargoDetailCode",
            headerName: this.$t('label.type'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        {
            field: "TpTransacEirAcronym" ,
            headerName: this.$t('label.transaction'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        ,
        {
            field: "BlNro",
            headerName: this.$t('label.bl'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 250,
        }
        ,
        {
            field: "ConsigneeName",
            headerName: this.$t('label.consignee'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        }
        ,
        {
            field: "VisitDs", 
            headerName: this.$t('label.visit'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 350,
        }
        ,
        {
            field: "Ata",
            headerName: 'ATA',
            filter: 'agTextColumnFilter',
            cellRenderer: params => {
                let variable = '';
                
                if(params.value !== null && params.value !== '' ) 
                    variable = DateFormater.formatDateTimeWithSlash(params.value);
                
                return variable;
            },
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        },
        {
            field: "PortActivityAbbrev",
            headerName: this.$t('label.portActivity'), 
            filter: 'agTextColumnFilter',
            cellClass: ' center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 200,
        },

      ]
      
      if(this.columnsOption.codeIso){
          columnDefs.push({
              field: "IsoCode",
              headerName: this.$t('label.isoCode'),  
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.condition){
          columnDefs.push({
              field: "TpCargoConditionCode",
              headerName: this.$t('label.Mode'),  
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.seal){
          columnDefs.push({
              field: "Seals",
              headerName: this.$t('label.Seals'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 250,
          });
      }

      if(this.columnsOption.diceContener){
          columnDefs.push({
              field: "DescriptionOfGoods",
              headerName: this.$t('label.inventoryContains'),  
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              cellRenderer: params => {
                return trimRemoveSpecialCharacters(params.value);
              },
              filter: "agTextColumnFilter",
              minWidth: 300,
          });
      }

      if(this.columnsOption.imdg){
          columnDefs.push({
              field: "ImdgCode",
              headerName: this.$t('label.imdg'),    
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.number){
          columnDefs.push({
              field: "UnNumber",
              headerName: this.$t('label.UnNumber'),
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.weight){
          columnDefs.push({
              field:  this.columnsOption.weightSelected === 1 ? "WeigthTon" : "WeigthKmg",
              headerName: this.columnsOption.weightSelected === 1 ?  `${this.$t('label.weight')}(TON)` :  `${this.$t('label.weight')}(KGM)`,  
              headerClass: 'center-cell-especial',
              cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        variable = NumberFormater.formatNumber(params.value, 2);
                    }
                    return variable;
              },
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.volume){
          columnDefs.push({
              field: "VolumenM3",
              headerName: `${this.$t('label.volume')}(M³)`,
              headerClass: 'center-cell-especial',
              cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        variable = NumberFormater.formatNumber(params.value, 2);
                    }
                    return variable;
              },
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.temperature){
          columnDefs.push({
              field: "Temperature",
              headerName: this.$t('label.temperature'),  
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.conected){
          columnDefs.push({
              field: "FgPlugged",
              headerName: `${this.$t('label.connected')}?`, 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              cellRenderer: params => {
                    let variable = '';

                    if(params.value !== null && params.value !== '' ) 
                        variable = params.value==0 ? this.$t('label.no')  : this.$t('label.yes') ;
                    
                    return variable;
              },
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }
      if(this.columnsOption.dinTop){
          columnDefs.push({
              field: "DimTop",
              headerName: this.$t('label.DimTop'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }


      if(this.columnsOption.dinLeft){
          columnDefs.push({
              field: "DimLeft",
              headerName: this.$t('label.DimLeft'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }
      if(this.columnsOption.dinRight){
          columnDefs.push({
              field: "DimRight",
              headerName: this.$t('label.DimRight'),  
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.dinFront){
          columnDefs.push({
              field: "DimFront",
              headerName: this.$t('label.DimFront'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.dinBack){
          columnDefs.push({
              field: "DimBack",
              headerName: this.$t('label.DimBack'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.rubro){
          columnDefs.push({
              field: this.$i18n.locale=='en' ? 'HeadingNameEn'  : 'HeadingNameEs' ,
              headerName: this.$t('label.Heading'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.dua){
          columnDefs.push({
              field: "Dua",
              headerName: this.$t('label.nDua'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.eir){
          columnDefs.push({
              field: "EirNumber",
              headerName: this.$t('label.nEir'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.receptionBp){
          columnDefs.push({
              field: "ReceptionBpAct",
              headerName: this.$t('label.bolipuertoReceptionRecordNro'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 350,
          });
      }

      if(this.columnsOption.customsRegime){
          columnDefs.push({
              field: this.$i18n.locale=='en' ? 'CustomOperationDsEn'  : 'CustomOperationDsEs' ,
              headerName: this.$t('label.customsRegime'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.broker){
          columnDefs.push({
              field: "CustomBrokerName",
              headerName: this.$t('label.customBroker'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }
      if(this.columnsOption.governmentPrivate){
          columnDefs.push({
              field: "FgGovernment",
              headerName: this.$t('label.govermentPrivate'),  
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              cellRenderer: params => {
                    let variable = '';

                    if(params.value !== null && params.value !== '' ) 
                        variable = params.value==false ? this.$t('label.no')  : this.$t('label.yes') ;
                    
                    return variable;
              },
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.origin){
          columnDefs.push({
              field: "Procedence",
              headerName: this.$t('label.Origin'),  
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.shippingLine){
          columnDefs.push({
              field: "ShippingLineName",
              headerName: this.$t('label.carrier'),   
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.currentPosicion){
        columnDefs.push({
            field: "CurrentPosition",
            headerName: this.$t('label.CurrentPosition'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 320,
        });
      }

      if(this.columnsOption.area){
        columnDefs.push({
            field: "AreaJson",
            headerName: this.$t('label.CurrentArea'), 
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial',
            filter: "agTextColumnFilter",
            minWidth: 200,
        });
      }



      if(this.columnsOption.dateReception){
          columnDefs.push({
              field: "ReceptionDate",
              headerName: this.$t('label.receptionDate'),    
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              cellRenderer: params => {
                    let variable = '';
                    
                    if(params.value !== null && params.value !== '' ) 
                        variable = DateFormater.formatDateTimeWithSlash(params.value);
                    
                    return variable;
              },
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.dayYard){
          columnDefs.push({
              field: "DaysInYard",
              headerName: this.$t('label.daysInYard'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.actualStatus){
          columnDefs.push({
              field: this.$i18n.locale=='en' ? 'YardCargoStatusDsEn'  : 'YardCargoStatusDsEs' ,
              headerName: this.$t('label.actualStatus'),   
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial text-color-negrita',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.machine){
          columnDefs.push({
              field: "MachineName",
              headerName: this.$t('label.machine'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.damage){
          columnDefs.push({
              field: "FgDamage",
              headerName: this.$t('label.damageOption'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              cellRenderer: params => {
                    let variable = '';
                    
                    if(params.value !== null && params.value !== '' ) 
                        variable = params.value==0 ? this.$t('label.no')  : this.$t('label.yes') ;
                    
                    return variable;
              },
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.observation){
          columnDefs.push({
              field: "Observation",
              headerName: this.$t('label.observation'),    
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 300,
          });
      }

      if(this.columnsOption.user){
          columnDefs.push({
              field: "TransaLogin",
              headerName: this.$t('label.user'),  
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.device){
          columnDefs.push({
              field: "DeviceCode",
              headerName: this.$t('label.device'),   
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.status){
          columnDefs.push({
              field: "Status",
              headerName: this.$t('label.status'),   
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }
      if(this.columnsOption.dateTransaction){
          columnDefs.push({
              field: "TransaRegDate",
              headerName: this.$t('label.transactionDate'),  
              headerClass: 'center-cell-especial',
              cellRenderer: params => {
                    let variable = '';
                    
                    if(params.value !== null && params.value !== '' ) 
                        variable = DateFormater.formatDateTimeWithSlash(params.value);
                    
                    return variable;
              },
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

    return columnDefs;
}

//methods
function getYardVisitListReception() {
    this.$http.get('YardVisit-list', { CompanyBranchId: this.CompanyBranchId })
        .then(response => {
            this.VisitList = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
}

function getYardVisitList() {
    this.$store.state.yardManagement.loading = true;

    let dateStart = '';
    let dateEnd = '';

    dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
    dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);        

    let ReportJson = {
        "YardId": this.yardData?.YardId ?? '',//Id del Patio 
        "Visit": this.Visit?.VisitId ?? '',//Id de la Visita seleccionada
        "DateStart": dateStart,//Fecha desde 
        "DateEnd": dateEnd,//Fecha hasta (Fecha Actual valor maximo)
    };

    this.$http.post('YardReceptionImport-list',ReportJson, { root: 'ReportJson'})
        .then(response => {
            this.VisitName = this.Visit;
            let List = response.data.data;
            this.formatedItems = Object.entries(List[0]).length > 1 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = false;
        });
}

function onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
}

function getDateRange(){
    this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
    this.laterDate = new Date();
    this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
    this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

function onQuickFilterChanged(value) {
    this.gridApi.setQuickFilter(value);
}

function onPageSizeChanged(event) {
    this.gridApi.paginationSetPageSize(parseInt(event.target.value));
}

async function refreshComponent() {
    this.formatedItems = [];
    this.Visit="";
    this.VisitName = {};
    this.searchFilterDate = {
        fromDay: '',
        toDay: '',
    };
    this.DateRange = '';
    this.previousDate = '';
    this.laterDate = '';
    await this.getDateRange();
    this.columnsOption = {
      filter:true,
      selectAll:true,
      seal:true,
      dateReception:true,
      number:true,
      broker:true,
      machine: true,
      codeIso:true,
      condition:true,
      diceContener:true,
      dua:true,
      eir: true,
      imdg:true,
      weight:true,
      volume:true,
      temperature:true,
      conected:true,
      dinTop:true,
      dinLeft:true,
      dinRight:true,
      dinFront:true,
      dinBack:true,
      rubro:true,
      governmentPrivate:true,
      origin:true,
      shippingLine:true,
      currentPosicion:true,
      area: true,
      user:true,
      device:true,
      dateTransaction:true,
      observation:true,
      damage: true,
      status: true,
      receptionBp:true,
      customsRegime:true,
      actualStatus: true,
      dayYard: true,
      weightSelected: 1,
      UnitMeasureWeigth:[
        {
            value: 1,
            label: 'TON'
        },
        {
            value: 2,
            label: 'KGM'
        }
      ],
    }
}


async function onBtnExport(valor){
    this.$store.state.yardManagement.loading = true;
    let currentDate = new Date();
    let rowData = [];
    let valores = []; 
    let rowDataFormated = [];

    valores[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
    valores[1] = this.$t('label.visit')+': '+(this.VisitName?.VisitId ? this.VisitName?.VesselName+' - '+this.VisitName?.VoyageArrival+ ' - ATA: '+this.VisitName?.Ata : 'N/A');
    valores[2] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
    valores[3] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

    this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
    
    for (let i = 0; i < rowData.length; i++) {
        rowDataFormated.push({
            ...rowData[i],
            Nro:rowData[i].Nro ? rowData[i].Nro : 'N/A',
            BlNro: rowData[i].BlNro ? `${rowData[i].BlNro}`.toUpperCase() : 'N/A',
            Ata : rowData[i].Ata ? DateFormater.formatDateTimeWithSlash(rowData[i].Ata) : 'N/A',
            WeigthTon : rowData[i].WeigthTon ? NumberFormater.formatNumber(rowData[i].WeigthTon, 2) : 0,
            VolumenM3:  rowData[i].VolumenM3 ? NumberFormater.formatNumber(rowData[i].VolumenM3, 2) : 0,
            FgPlugged :  rowData[i].FgPlugged==0 ? this.$t('label.no')  : this.$t('label.yes')  ?? 'N/A',
            ReceptionDate : rowData[i].ReceptionDate ? DateFormater.formatDateTimeWithSlash(rowData[i].ReceptionDate) : 'N/A',
            FgDamage : rowData[i].FgDamage==0 ? this.$t('label.no')  : this.$t('label.yes')  ?? 'N/A',
            FgGovernment: rowData[i].FgGovernment==0 ? this.$t('label.no')  : this.$t('label.yes') ?? 'N/A',
            TransaRegDate : rowData[i].TransaRegDate ? DateFormater.formatDateTimeWithSlash(rowData[i].TransaRegDate) : 'N/A',
            DescriptionOfGoods: rowData[i].DescriptionOfGoods ? trimRemoveSpecialCharacters(rowData[i].DescriptionOfGoods) : 'N/A',
            Procedence: rowData[i].Procedence? `${rowData[i].Procedence}`.toUpperCase()  : 'N/A'
        });   
    }
    
    if(this.nulo!="null" && this.formatedItems.length !== 0) {
        await this.getReporExcel(rowDataFormated, `${this.$t('label.listReception')}`, valor, valores);
    }else{
        this.$notify({  
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.noRecordsAvailbleReport'),
            type: "error" 
        });
    }
    this.$store.state.yardManagement.loading = false;
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getRowClass(params) {
    return 'bg-gradient'
}

function getRowStyle(params) {
    return { background: params.data.YardCargoStatusColor};
}


export default {
  name: 'recepcion-list',
  components: {
    ModalRegister
   },
  data,
  beforeMount,
  mixins: [GeneralMixin, YardManagement, AgGrid],
  methods: {
    onGridReady,
    onQuickFilterChanged,
    onPageSizeChanged,
    onBtnExport,
    validateDateRange,
    getRowClass,
    getYardVisitList,
    getYardVisitListReception,
    getRowStyle,
    getDateRange,
    refreshComponent,
  },
  computed: {
    RowData,
    columnDefs,
    visitFormatted,
    localeText,
    ...mapState({
        dropItemReport: state => state.yardManagement.dropItemReport,
        yardData: state => state.yardManagement.yardData,
        CompanyBranchId: state => state.auth.branch.CompanyBranchId,
    })
  },
  directives: UpperCase,
  watch: {
    dropItemReport: async function (val) {
        if (val==1) {
            this.$store.state.yardManagement.loading = true;
            await this.refreshComponent();
            await this.getYardVisitListReception();
            await this.getYardVisitList();
        }
    },
    Visit: function (val) {
        if(val?.Ata){
            let ata = DateFormater.formatDateTimeWithoutSlash(val.Ata);
            this.previousDate = DateFormater.formatDateRange(ata);
            this.searchFilterDate.fromDay =val.Ata;
        }
        else{
            this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
            this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(process.env.VUE_APP_START_DATE_IN_PATIO);
        }
    },
    showGrid: function (NewValue) {
        if (NewValue) {
            this.$store.state.yardManagement.loading = false
        } else {
            this.$store.state.yardManagement.loading = true;
        }
    }
  },
};
</script>